<template>
  <section class="full-height">

    <div class="columns is-multiline is-centered is-gapless is-marginless">
      
      <div class="column is-10 is-offset-1 is-mobile is-narrow mt2rem">
        <div v-if="companyData" class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">{{ companyData.companies_id }} - {{ companyData.companies_name }} bignumber checker</div>
      </div>
      
      <div class="column is-10 is-offset-1 is-mobile is-narrow mb1rem">
        <h2>Upload excel file with column name BIGNUMBER, this will return excel file with checks:
        <ul>
          <li>1. Bignumber is WA user</li>
          <li>2. Bignumber is company_user records</li>
          <li>3. Company roles</li>
          <li>4. Default QUOTEM</li>
        </ul>
        </h2>
      </div>
    </div>
    
    
    <div>
      <div class="columns is-multiline is-centered is-gapless is-marginless">
        <div class="column is-10 is-offset-1">
          <input type="file" @change="uploadFile" ref="file" />
          
          <b-button @click="submitFile">Upload!</b-button>
        </div>   
      </div>   
    </div>
    
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      companyId           : null,
      companyData         : null,
      
      userFile            : null,
    }
  },
  
  async mounted(){
    this.companyId = this.$route.params.companyId;
    this.getCompanyData()
  },
  
  methods: {
    async getCompanyData() {
      this.sho
      let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.companyData  = response.data.payload.companyData
      
      this.HidePageLoading()
    },
    uploadFile() {
      this.userFile = this.$refs.file.files[0];
    },
    
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async submitFile() {
      if (!this.userFile) {
        return this.$buefy.dialog.alert({ title: 'Error', message: 'invalid file', type: 'is-danger' })
      }
      
      this.ShowPageLoading(5000)          
      
      const formData = new FormData();
      formData.append('file', this.userFile);
      const headers = { 'Content-Type': 'multipart/form-data' };
      let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/bigchecker/upload`, formData, { headers })
      console.info(`response:`, response)
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to upload template data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      console.info(`downloading:`, response.data.payload)
      const filename = encodeURIComponent(response.data.payload.excelFilename);      
      window.open(`${API_ROOT}/schedule/company/${this.companyId}/bigchecker/download?filename=${filename}`, '_blank')
      
      this.HidePageLoading()
    }
    
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }
    
    textarea {
      resize: none;
    }
    
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
    
    .inputheight2{
      /* background-color: red; */
      font-size: 14px;
      height: 300px;
    }
    
    .inputheight3{
      /* background-color: red; */
      font-size: 14px;
      height: 50px;
    }
    
    .inputheight4{
      /* background-color: red; */
      font-size: 14px;
      height: 150px;
    }
    .spacer{
      height: 50px;
      width: 100%;
    }
    
    .example_messages p{
      margin-top: 15px;
      padding: 2%;
    }
  

</style>